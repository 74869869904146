import { append } from "sbelt/dom";
import { _token } from "./currentUser";
import { get } from "svelte/store";

type JsonResponse<TResult extends { [key: string]: any } = {}> = {
  errorCode?: string;
  errorMessage?: string;
  extraData?: {};
} & {
  [P in keyof TResult]: TResult[P];
};

/**
 * Sends POST calls to server - use with @json_post_and_track_errors decorators only
 *
 * @param url
 * @param params
 */
export async function postJson<TResult extends {}>(url: string, params: {} | FormData | null = null): Promise<TResult> {
  const csrf_token = get(_token);
  if (csrf_token) {
    if (!params) {
      params = {};
    }
    (params as any)["_token"] = csrf_token;
  }
  const body = JSON.stringify(params);

  let response;
  try {
    response = await fetch(url, {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body,
      method: "POST",
      credentials: "same-origin"
    });
  } catch (e) {
    throw new Error(`HTTP POST JSON Error ${url}, ${e.message}`);
  }

  if (response.status === 401) {
    // Force redirect to homepage
    document.location.href = "/";
    throw new Error("redirect required");
  }
  let json: JsonResponse<TResult>;
  try {
    json = await response.json();
  } catch (e) {
    throw new ServerError();
  }
  if (response.status > 299) {
    throw new ServerError(json.errorCode, json.errorMessage, json.extraData);
  }

  return json as TResult;
}

export async function postForm(url: string, params?: {} | FormData): Promise<string> {
  const body = createBody(params);

  let response;
  try {
    response = await fetch(url, {
      body,
      method: "POST",
      credentials: "include"
    });
  } catch (e) {
    throw new Error(`HTTP POST JSON Error ${url}, ${e.message}`);
  }

  if (response.status === 401) {
    // Force redirect to homepage
    document.location.href = "/";
    throw new Error("redirect required");
  }

  const t = await response.text();
  return t;
}

/**
 * Sends GET calls to server - use with @json_get_and_track_errors decorators only
 *
 * @param url
 * @param params
 */
export async function getJson<TResult extends {}>(url: string): Promise<TResult> {
  let response;
  try {
    response = await fetch(url, {
      method: "GET",
      credentials: "include"
    });
  } catch (e) {
    throw new Error(`HTTP GET JSON Error ${url}, ${e.message}`);
  }

  if (response.status === 401) {
    // Force redirect to homepage
    document.location.href = "/";
    throw new Error("redirect required");
  }
  let json: JsonResponse<TResult>;
  try {
    json = await response.json();
  } catch (e) {
    throw new ServerError();
  }
  if (response.status > 299) {
    throw new ServerError(json.errorCode, json.errorMessage, json.extraData);
  }

  return json as TResult;
}

function createBody(input: { [id: string]: string | number } | FormData | undefined): FormData | null {
  if (!input) {
    return null;
  }

  if (input instanceof FormData) {
    return input;
  }

  const data = new FormData();

  Object.keys(input).forEach((k) => {
    const value = input[k];

    if (Array.isArray(value)) {
      // Handle the special, but annoying case of sending an array in formData
      const arrayKey = k.indexOf("[]") > 0 ? k : `${k}[]`;
      value.forEach((v) => {
        data.append(arrayKey, String(v));
      });
    } else {
      data.set(k, String(input[k]));
    }
  });

  return data;
}

// To find user errors -
export class ServerError extends Error {
  static readonly GENERIC_ERROR = "Oops, something went wrong";

  constructor(public code?: string, message?: string, public extraData?: Record<any, any>) {
    // In case we got an non-UserException error from the server (see "ApiException" class in server), hide the real exception message.
    super(!code || code === "unknown" ? ServerError.GENERIC_ERROR : message);
  }

  toMessage(messagesByCode: { [code: string]: string }) {
    return (messagesByCode && messagesByCode[this.code]) || ServerError.GENERIC_ERROR;
  }
}
